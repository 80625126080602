import { Component } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';


export let settings = {
  actions: {
    add: false,
    edit: false,
    delete: false,
    
  },

  columns: {
    id: {
      title: 'ID',
      filter: false
    },
    name: {
      title: 'Full Name',
      filter: false
    },
    username: {
      title: 'Med Compliance',
      filter: false
    },
    email: {
      title: 'Narcotic Intake',
      filter: false
    }
  },
  edit: {
    editButtonContent: '<i class="ti-pencil text-info m-r-10"></i>',
    saveButtonContent: '<i class="ti-save text-success m-r-10"></i>',
    cancelButtonContent: '<i class="ti-close text-danger"></i>'
  },
  delete: {
    deleteButtonContent: '<i class="ti-trash text-danger m-r-10"></i>',
    saveButtonContent: '<i class="ti-save text-success m-r-10"></i>',
    cancelButtonContent: '<i class="ti-close text-danger"></i>'
  }
};
export let settings2 = {
  actions: {
    add: false,
    edit: false,
    delete: false,
    
  },

  columns: {
    id: {
      title: 'ID',
      filter: false
    },
    name: {
      title: 'Full Name',
      filter: false
    },
    username: {
      title: 'Med Compliance',
      filter: false
    },
    email: {
      title: 'Narcotic Intake',
      filter: false
    }
  },
  edit: {
    editButtonContent: '<i class="ti-pencil text-info m-r-10"></i>',
    saveButtonContent: '<i class="ti-save text-success m-r-10"></i>',
    cancelButtonContent: '<i class="ti-close text-danger"></i>'
  },
  delete: {
    deleteButtonContent: '<i class="ti-trash text-danger m-r-10"></i>',
    saveButtonContent: '<i class="ti-save text-success m-r-10"></i>',
    cancelButtonContent: '<i class="ti-close text-danger"></i>'
  }
};
export let settings3 = {
  actions: {
    add: false,
    edit: false,
    delete: false,
    
  },

  columns: {
    id: {
      title: 'ID',
      filter: false
    },
    name: {
      title: 'Name',
      filter: true
    },
    address: {
      title: 'Address',
      filter: false,
    },
  },
  
  edit: {
    editButtonContent: '<i class="ti-pencil text-info m-r-10"></i>',
    saveButtonContent: '<i class="ti-save text-success m-r-10"></i>',
    cancelButtonContent: '<i class="ti-close text-danger"></i>'
  },
  delete: {
    deleteButtonContent: '<i class="ti-trash text-danger m-r-10"></i>',
    saveButtonContent: '<i class="ti-save text-success m-r-10"></i>',
    cancelButtonContent: '<i class="ti-close text-danger"></i>'
  }
};

export let settings4 = {
  
  actions: {
    add: false,
    edit: false,
    delete: false,
   
  },
  columns: {
    id: {
      title: 'ID',
      filter: false
    },
   image: {
    type: 'html',
    filter: false,
    
   },

    name: {
      title: 'Full Name',
      type: 'html',
      filter: false,
      
    },
    edit: {
      inputClass: '',
      editButtonContent: 'Edit',
      saveButtonContent: 'Update',
      cancelButtonContent: 'Cancel',
      confirmSave: false,
    },
    email: {
      title: 'Email',
      filter: false,
    },
    org:{
      title:'Affiliated Hospital/Organization',
      filter: false,
    },
    contact:{
      title: 'Contact',
      filter: false,
    },

  },
  
  edit: {
    editButtonContent: '<i class="ti-pencil text-info m-r-10"></i>',
    saveButtonContent: '<i class="ti-save text-success m-r-10"></i>',
    cancelButtonContent: '<i class="ti-close text-danger"></i>'
  },
  delete: {
    deleteButtonContent: '<i class="ti-trash text-danger m-r-10"></i>',
    saveButtonContent: '<i class="ti-save text-success m-r-10"></i>',
    cancelButtonContent: '<i class="ti-close text-danger"></i>'
  }

 
};
export let data = [
  {
    
    id: 1,
    image:'assets/images/users/1.jpg',
     
    name: 'Leanne Graham',
    med: 'xxxxxxxxx',
    narcotic: 'xxxxxxxx',
    reports:'xxxxxxx',
    vitals: 'xxxxxxxx',
    
  fname:'Leanne',
  age:'23',
  reason:'abc',
  email:'abc',
  username:'admin',
  password:'admin',
  discharge:'abc',
  disdate:'27 Dec 2018',
  contact:'xxxxxxxxxx',
  clinic:'xxxxxxx',
  doctor:'xxxxxx',
  care:'xxxxx',
  surgical:'xxxxxx',
  medurl:'xxxxxxx',
    
  },
  {
    id: 2,
    image:'assets/images/users/1.jpg',
    name: 'Jhon',
    med: 'xxxxxxxxxxxx',
    narcotic: 'xxxxxxxxxxx',
    reports:'xxxxxxx',
    vitals: 'xxxxxxx',

  fname:'Jhon',
  age:'23',
  reason:'abc',
  email:'abc',
  username:'admin',
  password:'admin',
  discharge:'abc',
  disdate:'27 Dec 2018',
  contact:'xxxxxxxxxx',
  clinic:'xxxxxxx',
  doctor:'xxxxxx',
  care:'xxxxx',
  surgical:'xxxxxx',
  medurl:'xxxxxxx',
  },
  {
    id: 3,
    image:'assets/images/users/1.jpg',
    name: 'Leanne Graham',
    med: 'xxxxxxxx',
    narcotic: 'xxxxxxx',
    reports:'xxxxxx',
    vitals: 'xxxxxx',


  fname:'Leanne',
  age:'23',
  reason:'abc',
  email:'abc',
  username:'admin',
  password:'admin',
  discharge:'abc',
  disdate:'27 Dec 2018',
  contact:'xxxxxxxxxx',
  clinic:'xxxxxxx',
  doctor:'xxxxxx',
  care:'xxxxx',
  surgical:'xxxxxx',
  medurl:'xxxxxxx',
  },
  {
    id: 4,
    image:'assets/images/users/1.jpg',
    name: 'Leanne Graham',
    med: 'xxxxxx',
    narcotic: 'xxxxxx',
    reports:'xxxxxxx',
    vitals: 'xxxxxxx',

  fname:'Leanne',
  age:'23',
  reason:'abc',
  email:'abc',
  username:'admin',
  password:'admin',
  discharge:'abc',
  disdate:'27 Dec 2018',
  contact:'xxxxxxxxxx',
  clinic:'xxxxxxx',
  doctor:'xxxxxx',
  care:'xxxxx',
  surgical:'xxxxxx',
  medurl:'xxxxxxx',
  },
  {
    id: 5,
    image:'assets/images/users/1.jpg',
    name: 'Leanne Graham',
    med: 'xxxxxxxx',
    narcotic: 'xxxxxxxx',
    reports:'xxxxxxxx',
    vitals: 'xxxxxxx',

  fname:'Leanne',
  age:'23',
  reason:'abc',
  email:'abc',
  username:'admin',
  password:'admin',
  discharge:'abc',
  disdate:'27 Dec 2018',
  contact:'xxxxxxxxxx',
  clinic:'xxxxxxx',
  doctor:'xxxxxx',
  care:'xxxxx',
  surgical:'xxxxxx',
  medurl:'xxxxxxx',
  },
  {
    id: 6,
    image:'assets/images/users/1.jpg',
    name: 'Leanne Graham',
    med: 'xxxxxxxx',
    narcotic: 'xxxxxxx',
    reports:'xxxxxxx',
    vitals: 'xxxxxxx',

  fname:'Leanne',
  age:'23',
  reason:'abc',
  email:'abc',
  username:'admin',
  password:'admin',
  discharge:'abc',
  disdate:'27 Dec 2018',
  contact:'xxxxxxxxxx',
  clinic:'xxxxxxx',
  doctor:'xxxxxx',
  care:'xxxxx',
  surgical:'xxxxxx',
  medurl:'xxxxxxx',
  },
  {
    id: 7,
    image:'assets/images/users/1.jpg',
    name: 'Leanne Graham',
    med: 'xxxxxxxx',
    narcotic: 'xxxxxxxx',
    reports:'xxxxxxx',
    vitals: 'xxxxxxx',
    
  fname:'Leanne',
  age:'23',
  reason:'abc',
  email:'abc',
  username:'admin',
  password:'admin',
  discharge:'abc',
  disdate:'27 Dec 2018',
  contact:'xxxxxxxxxx',
  clinic:'xxxxxxx',
  doctor:'xxxxxx',
  care:'xxxxx',
  surgical:'xxxxxx',
  medurl:'xxxxxxx',
  },
  {
    id: 8,
    image:'assets/images/users/1.jpg',

  fname:'Leanne',
  age:'23',
  reason:'abc',
  email:'abc',
  username:'admin',
  password:'admin',
  discharge:'abc',
  disdate:'27 Dec 2018',
  contact:'xxxxxxxxxx',
  clinic:'xxxxxxx',
  doctor:'xxxxxx',
  care:'xxxxx',
  surgical:'xxxxxx',
  medurl:'xxxxxxx',
  
    name: 'Leanne Graham',
    med: 'xxxxxxxx',
    narcotic: 'xxxxxxxx',
    reports:'xxxxxx',
    vitals: 'xxxxxxx',
   
  },
]
  



export let data1 = [
  {
    id: 1,
    name: 'Leanne Graham',
    address: 'xxxxxxxxx',
  },
  {
    id: 2,
    name: 'Leanne Graham',
    address: 'xxxxxxxxxxxx',
  },
  {
    id: 3,
    name: 'Leanne Graham',
    address: 'xxxxxxxxxxxx',
  },
  {
    id: 4,
    name: 'Leanne Graham',
    address: 'xxxxxxxxxxxx',
  },
  {
    id: 5,
    name: 'Leanne Graham',
    address: 'xxxxxxxxxxxx',
  },
  {
    id: 6,
    name: 'Leanne Graham',
    address: 'xxxxxxxxxxxx',
  },
  {
    id: 7,
    name: 'Leanne Graham',
    address: 'xxxxxxxxxxxx',
  },
  {
    id: 8,
    name: 'Leanne Graham',
    address: 'xxxxxxxxxxxx',
  },
];